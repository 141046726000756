// import React, { useContext, createContext, FC, useEffect, useReducer } from 'react';
// import { CompanyStateI, CompanyActionTypes, companyInitialState } from 'store';
// import { companyReducer } from 'store/company/reducers';
// import API from 'services/AxiosConfig';

// const STATE_LS_KEY = 'company';

// export interface Context extends CompanyStateI {
//   setLocations: (locations: any[]) => void;
//   setUsers: (users: any[]) => void;
//   setClients: (clients: any[]) => void;
//   setCarePartners: (carePartners: any[]) => void;
//   resetCompany: () => void;
//   setCareManagerActivities: (activities: any[]) => void;
//   setApplicants: (applicants: any[]) => void;
// }

// const LSinitialState = JSON.parse(localStorage.getItem(STATE_LS_KEY) as string) || companyInitialState;

// export const CompanyContext = createContext({} as Context);

// export const CompanyContextProvider: FC = ({ children }) => {
//   const [state, dispatch] = useReducer(companyReducer, LSinitialState);

//   const setLocations: Context['setLocations'] = (locations: any[]) => {
//     dispatch({ type: CompanyActionTypes.SET_LOCATIONS, payload: locations });
//   };

//   const setUsers: Context['setUsers'] = (users: any[]) => {
//     dispatch({ type: CompanyActionTypes.SET_USERS, payload: users });
//   };

//   const setClients: Context['setClients'] = (clients: any[]) => {
//     dispatch({ type: CompanyActionTypes.SET_CLIENTS, payload: clients });
//   };

//   const setCarePartners: Context['setCarePartners'] = (carePartners: any[]) => {
//     dispatch({ type: CompanyActionTypes.SET_CAREPARTNERS, payload: carePartners });
//   };

//   const resetCompany: Context['resetCompany'] = () => {
//     dispatch({ type: CompanyActionTypes.RESET });
//   };

//   const setCareManagerActivities: Context['setCareManagerActivities'] = (activities: any[]) => {
//     dispatch({ type: CompanyActionTypes.SET_CARE_MANAGER_ACTIVITIES, payload: activities });
//   };
//   const setApplicants: Context['setApplicants'] = (activities: any[]) => {
//     dispatch({ type: CompanyActionTypes.SET_APPLICANTS, payload: activities });
//   };
  
//   useEffect(() => {
//     localStorage.setItem(STATE_LS_KEY, JSON.stringify(state));
//   }, [state]);

//   return (
//     <CompanyContext.Provider
//       value={{
//         setLocations,
//         setUsers,
//         setClients,
//         setCarePartners,
//         resetCompany,
//         setCareManagerActivities,
//         setApplicants,
//         ...state,
//       }}
//     >
//       {children}
//     </CompanyContext.Provider>
//   );
// };

// export default function useAuth() {
//   const context = useContext(CompanyContext);

//   /// TODO: Remove this is next release///
//   if(!localStorage.getItem('updated')) {
//     const { setUsers } = context
//     API.get('user/null').then((usersData) => {
//       setUsers(usersData.data.data);
//     })
//   }
//   localStorage.setItem('updated', 'true')
//   ///////////////////////////////////////
//   return context;
// }
import React, { useContext, createContext, FC, useEffect, useReducer } from 'react';
import localForage from 'localforage';
import { CompanyStateI, CompanyActionTypes, companyInitialState } from 'store';
import { companyReducer } from 'store/company/reducers';
import API from 'services/AxiosConfig';

const STATE_LS_KEY = 'company';

export interface Context extends CompanyStateI {
  setLocations: (locations: any[]) => void;
  setUsers: (users: any[]) => void;
  setClients: (clients: any[]) => void;
  setCarePartners: (carePartners: any[]) => void;
  resetCompany: () => void;
  setCareManagerActivities: (activities: any[]) => void;
  setApplicants: (applicants: any[]) => void;
}

export const CompanyContext = createContext({} as Context);

export const CompanyContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(companyReducer, companyInitialState);

  // Load initial state from localForage
  useEffect(() => {
    const loadState = async () => {
      const storedState = await localForage.getItem<CompanyStateI>(STATE_LS_KEY);
      if (storedState) {
        dispatch({ type: CompanyActionTypes.LOAD_STATE, payload: storedState });
      }
    };
    loadState();
  }, []);

  useEffect(() => {
    localForage.setItem(STATE_LS_KEY, state);
  }, [state]);

  const setLocations: Context['setLocations'] = (locations: any[]) => {
    dispatch({ type: CompanyActionTypes.SET_LOCATIONS, payload: locations });
  };

  const setUsers: Context['setUsers'] = (users: any[]) => {
    dispatch({ type: CompanyActionTypes.SET_USERS, payload: users });
  };

  const setClients: Context['setClients'] = (clients: any[]) => {
    dispatch({ type: CompanyActionTypes.SET_CLIENTS, payload: clients });
  };

  const setCarePartners: Context['setCarePartners'] = (carePartners: any[]) => {
    dispatch({ type: CompanyActionTypes.SET_CAREPARTNERS, payload: carePartners });
  };

  const resetCompany: Context['resetCompany'] = async () => {
    await localForage.removeItem(STATE_LS_KEY); // Delete stored company state
    dispatch({ type: CompanyActionTypes.RESET });
  };

  const setCareManagerActivities: Context['setCareManagerActivities'] = (activities: any[]) => {
    dispatch({ type: CompanyActionTypes.SET_CARE_MANAGER_ACTIVITIES, payload: activities });
  };

  const setApplicants: Context['setApplicants'] = (applicants: any[]) => {
    dispatch({ type: CompanyActionTypes.SET_APPLICANTS, payload: applicants });
  };

  return (
    <CompanyContext.Provider
      value={{
        setLocations,
        setUsers,
        setClients,
        setCarePartners,
        resetCompany,
        setCareManagerActivities,
        setApplicants,
        ...state,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

// export default function useAuth() {
//   const context = useContext(CompanyContext);

//   useEffect(() => {
//     const updateUsers = async () => {
//       const updated = await localForage.getItem('updated');
//       if (!updated) {
//         const { setUsers } = context;
//         API.get('user/null').then((usersData) => {
//           setUsers(usersData.data.data);
//         });
//         await localForage.setItem('updated', 'true');
//       }
//     };
//     updateUsers();
//   }, [context]);

//   return context;
// }

export default function useAuth() {
  const context = useContext(CompanyContext);

  /// TODO: Remove this is next release///
  if(!localStorage.getItem('updated')) {
    const { setUsers } = context
    API.get('user/null').then((usersData) => {
      setUsers(usersData.data.data);
    })
  }
  localStorage.setItem('updated', 'true')
  ///////////////////////////////////////
  return context;
}