enum CompanyActionTypes {
  LOAD_STATE = 'LOAD_STATE',
  SET_LOCATIONS = 'SET_LOCATIONS',
  SET_USERS = 'SET_USERS',
  SET_CLIENTS = 'SET_CLIENTS',
  SET_CAREPARTNERS = 'SET_CAREPARTNERS',
  RESET = 'RESET',
  SET_CARE_MANAGER_ACTIVITIES = 'SET_CARE_MANAGER_ACTIVITIES',
  SET_APPLICANTS = 'SET_APPLICANTS',
}

export { CompanyActionTypes };
